.custom .rbc-month-view {
  padding: 2rem; /* Add your desired padding */
  background: #ffffff;
  border: 1px solid #eff0f6;
  border-radius: 10px;
}
.custom .rbc-time-view {
  padding: 2rem; /* Add your desired padding */
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #eff0f6;
}
