.feedback-slide {
  width: calc(
    96% - 20px
  ) !important; /* Adjust width to account for margin or padding */
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-prev,
.slick-next {
  width: 30px;
  height: 30px;
  z-index: 1;
}

.slick-prev::before,
.slick-next::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: -10px;
}

.slick-next {
  right: -10px;
}

.slick-prev::before {
  background-image: url("/public/img/sliderback.svg");
  /* background-image: url("/public/img/sliderback.svg");  */
}

.slick-next::before {
  background-image: url("/public/img/slidernext.svg"); /* Replace with your next icon URL */
}
