.custom-input {
  border-radius: 10px;
  border: 1px solid #d5d5d5;
  padding: 10px 12px;
  font-size: 16px;
}
.custom-input::placeholder {
  color: #bfbfbf;
  font-size: 16px;
}
