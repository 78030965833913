@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button-background-gradient {
  border-radius: 10px;
  background: linear-gradient(180deg, #0116bb 20%, #010b64 100%, #010e78 100%);
  color: white;
  cursor: pointer;
  text-align: center;
}

.background-gradient {
  background: linear-gradient(180deg, #0116bb 20%, #010b64 100%, #010e78 100%);
  color: white;
}

.button-background-gradient-90 {
  border-radius: 10px;
  background: linear-gradient(90deg, #0116bb 0.68%, #010e78 100.14%);
  color: white;
  cursor: pointer;
  text-align: center;
}

.box-shadow {
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.07),
    0px 4px 10px 0px rgba(0, 0, 0, 0.07);
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-drawer-content-wrapper {
  width: 0px !important;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: #828282;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.rbc-header {
  background: var(--bg-primary-color);
  color: white;
  padding: 4px;
  font-weight: bold;
}
.rbc-header.rbc-today {
  background-color: yes !important;
  background: var(--bg-primary-color);
}
.rbc-event {
  padding: 0 !important;
  font-size: 16px;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  background-color: var(--bg-primary-color) !important;
  color: var(--text-primary-color) !important;
  padding: 5px !important;
}

ul {
  list-style: circle;
}
.react-pdf__Page__textContent {
  display: none !important;
}

.react-pdf__Page__canvas {
  height: 580px !important;
  width: 580px !important;
}

@media (max-width: 520px) {
  .react-pdf__Page__canvas {
    height: 320px !important;
    width: 320px !important;
  }
}
